import router from '@/router'
import { useCustomerStore } from '@/state/customer'
import { getToken } from '@/helpers/auth'
import { AxiosInstance } from 'axios'
import { notifyError } from '@/helpers/notification'
import { useAppStore } from '@/state/app'

const errorResponseHandler = async (error: any) => {
  const auth = useCustomerStore()
  const app = useAppStore()
  const status = error.response?.status
  const path = router.currentRoute.value.fullPath

  if (status === 401) {
    if (path !== '/sign-in') router.push({ name: 'SignIn' })
    app.stopLoading()
    auth.logout()
  }

  await notifyError((error.response.data.message as string) || 'Unknown Error')

  return Promise.reject(error)
}

const applyBaseInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    response => response,
    errorResponseHandler
  )
  axiosInstance.interceptors.request.use(config => {
    if (getToken() && config.headers) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  })
}

export default {
  use(axiosInstance: AxiosInstance) {
    applyBaseInterceptors(axiosInstance)
  }
}

export const getValidationErrors = data => {
  if (data && data.length) {
    return data.reduce((prev, { field, message }) => {
      const isFieldExist = prev[field]

      if (isFieldExist) {
        prev[field] += ` ${message}`
        return prev
      }

      prev[field] = message

      return prev
    }, {})
  }
  return {}
}
