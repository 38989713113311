import BaseService from '@/services/base-service'
import { Stores } from 'package-types'
import { Customers } from 'package-types'

export default {
  async getCustomer(): Promise<Customers.Actions.GetCustomerResult> {
    const { data } = await BaseService.get('/customers')
    return data
  },

  async updateCustomer(
    form: Partial<Customers.Actions.UpdateCustomerArguments>
  ): Promise<Customers.Actions.UpdateCustomerResult> {
    const { data } = await BaseService.put('/customers', form)
    return data
  },

  async getQrCode(): Promise<Customers.Actions.GetQrCodeResult> {
    const { data } = await BaseService.get('/customers/qrCode')
    return data
  },

  async initAgeVerification(
    storeId: string
  ): Promise</*Customers.Actions.InitAgeVerificationResult*/ any> {
    const { data } = await BaseService.post(
      '/customers/ageVerification/pxlVision',
      {
        storeId
      }
    )
    return data
  },
  async ageVerificationWalkout(photo: FormData): Promise<any> {
    const { data } = await BaseService.post(
      '/customers/ageVerification/walkoutAi',
      photo
    )
    return data
  },

  async getAgeVerificationConfig(storeId: string): Promise<any> {
    const { data } = await BaseService.get(
      `/customers/ageVerification/config?storeId=${storeId}`
    )
    return data
  },

  async initResetPassword(
    form: Customers.Actions.InitResetPasswordArguments['params']
  ): Promise<Customers.Actions.InitResetPasswordResult> {
    const { data } = await BaseService.post(
      '/customers/initResetPassword',
      form
    )
    return data
  },

  async resetPassword(
    form: Customers.Actions.ResetPasswordArguments['params']
  ): Promise<Customers.Actions.ResetPasswordResult> {
    const { data } = await BaseService.post('/customers/resetPassword', form)
    return data
  },

  async getVisitor(): Promise<Stores.Actions.CustomerGetVisitorResult> {
    const { data } = await BaseService.get('/stores/visitor')
    return data
  }
}
