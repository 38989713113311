import { setupLanguage } from '@/helpers/language'
import { createI18n } from 'vue-i18n'
import de from './locales/de'
import en from './locales/en'

const messages = {
  en,
  de
}

export const i18n = createI18n({
  locale: setupLanguage(),
  fallbackLocale: 'en',
  messages
})
