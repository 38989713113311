import { Customers } from 'package-types'

export default {
  common: {
    enter: 'eingeben',
    confirm: 'bestätigen',
    back: 'zurück',
    below18: 'Ihr Alter unter 18 (FSK18)',
    ageNotVerified: 'Alter nicht verifiziert',
    qrAgeChecked: 'Der Zugang zu diesem Bereich ist erlaubt',
    save: 'Speichern',
    validation: {
      required: 'Dieses Feld wird benötigt',
      email: 'E-Mail ist falsch',
      phone: 'Telefonnummer ungültig',
      passwordMatch: 'Passwörter müssen übereinstimmen',
      password:
        'Das Passwort muss mindestens 6 Zeichen enthalten, einen Groß- und einen Kleinbuchstaben'
    },
    ty: 'Danke',
    successfulPayment: 'Ihre Bezahlung war erfolgreich',
    captured: 'wurden erbeutet'
  },

  splash: {
    welcome: 'Willkommen bei',
    desc: 'Ihr digitaler Supermarkt für Convenience und den täglichen Einkauf!',
    go: 'Let’s go'
  },

  instruction: {
    slide1: 'Betritt den Laden durch das Gate. ',
    slide2: 'Entnehme deine Produkte aus dem Regal.',
    slide3:
      'Für den Kauf von FSK 18 Produkten nimm bitte die Verifizierung in der App',
    slide4: '...& Scan den QR-Code am Regal.',
    slide5: 'Scanne und Bezahle deine Artikel am Ausgang beim Self-Checkout',
    skip: 'Überspringen',
    snabble: {
      slide1:
        'Bitte registrieren Sie sich und geben Sie eine Kreditkartenzahlung ein.',
      slide2:
        'Für den Kauf von FSK 16 oder FSK 18 Produkten nimm bitte die Verifizierung in der App',
      slide3: 'Scannen Sie Ihren QR-Code am Eingangstor',
      slide4: 'Dann können Sie die gewünschten Produkte nehmen'
    }
  },

  menu: {
    inStore: 'In Store',
    orders: 'Bestellungen',
    payment: 'Depot',
    account: 'Account'
  },
  signIn: {
    title: 'Login',
    login_btn: 'Login',
    register_btn: 'REGISTRIEREN',
    forgotPassword: 'Passwort vergessen?',
    form: {
      emailLabel: 'Email',
      passwordLabel: 'Passwort'
    }
  },
  signUp: {
    title: 'Registrierung',
    personalInfo: 'Persönliche Informationen',
    billingAddress: 'Rechnungsadresse',
    password: 'Passwort',
    terms1: 'Indem Sie ein Konto erstellen, stimmen Sie den',
    terms2: 'und den',
    terms3: 'zu.',
    form: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'Email',
      password: 'Passwort',
      repeatPassword: 'Passwort bestätigen',
      address: 'Adresse',
      postalCode: 'PLZ',
      city: 'Stadt',
      houseNumber: 'Nummer',
      phone: 'Telefonnummer',
      save: 'Jetzt registrieren'
    }
  },
  store: {
    scanQr: 'QR-Code scannen, um in den Store zu gelangen',
    wrongQr: 'Falscher QR-CODE. Bitte scannen Sie erneut',
    verificationStatuses: {
      [Customers.VerificationStatus.Completed]: 'Alter verifiziert',
      [Customers.VerificationStatus.Failed]: 'Alter nicht verifiziert',
      [Customers.VerificationStatus.InProgress]: 'Altersüberprüfung läuft'
    },

    addPayment: 'Hier müssen Sie die Standardzahlungsmethode',
    selectPayment: 'Hier müssen Sie die Standardzahlungsmethode',
    here: 'auswählen'
  },
  account: {
    userData: 'Nutzerdaten',
    accountSettings: 'Account Einstellungen',
    language: 'Sprache',
    ageConfirmation: 'Altersverifizierung',
    dataProtection: 'Datenschutz',
    privacy: 'AGB',
    changePassword: 'Passwort ändern',
    lang_change: 'Die Sprache wurde geändert',
    coupon: 'Gutschein',
    logout: 'Ausloggen',
    languages: {
      de: 'Deutsch',
      en: 'English'
    },
    verificationStatuses: {
      [Customers.VerificationStatus.Completed]: 'Verifiziert',
      [Customers.VerificationStatus.Failed]: 'Nicht verifiziert',
      [Customers.VerificationStatus.InProgress]: 'Läuft'
    }
  },
  editCustomer: {
    title: 'Daten bearbeiten',
    save: 'Speichern',
    save_success: 'Ihre Daten wurden aktualisiert'
  },
  ageVerification: {
    title: 'Altersverifizierung',
    later: 'später',
    start: 'Start',
    takePhoto: 'Foto aufnehmen',
    benefits: {
      id: {
        title: 'Ausweis vergessen?',
        desc: 'Auch ohne Ausweis, vor Ort, kein Problem.'
      },
      time: {
        title: 'Spar Zeit',
        desc: 'Keine weitere Verifizierung vor Ort nötig.'
      }
    },
    statuses: {
      [Customers.VerificationStatus.Completed]: 'Your age was verified',
      [Customers.VerificationStatus.Failed]: 'Validierungsfehler',
      [Customers.VerificationStatus.InProgress]: 'Age verification pending...'
    },
    storeNotConfigured:
      'Sie können Ihr Alter im Moment nicht verifizieren. Bitte versuchen Sie es später noch einmal'
  },
  changePassword: {
    newPassword: 'Neues Passwort',
    currentPassword: 'Jetziges Passwort',
    repeatPassword: 'Passwort wiederholen',
    success: 'Passwort wurde geändert'
  },
  forgotPassword: {
    title: 'Passwort zurücksetzen',
    reset: 'Zurücksetzen',
    success: 'Passwort wurde erfolgreich geänder',
    emailSent: 'Ein Link zum Zurücksetzen des Passworts wurde gesendet'
  },

  voucher: {
    title: 'Gutschein',
    subTitle: 'Dein Willkommensangebot!',
    desс: 'Ein Gin Tonic oder Heißgetränk gratis',
    date: 'Gültig bis 20.07.2022'
  },
  basket: {
    title: 'Warenkorb',
    problems: 'Problems?',
    tax: 'MwSt.',
    product: 'Produkte'
  },
  troubleshooting: {
    title: 'Troubleshooting'
  },
  payment: {
    title: 'Depot',
    add: 'Zahlungsmethode hinzufügen',
    default: 'Hauptkarte',
    selectMain: 'Wählen Sie'
  },
  addPayment: {
    title: 'Zahlung hinzufügen'
  },
  orders: {
    title: 'Bestellungen',
    total: 'Gesamt',
    orderFrom: 'Bestellung vom'
  }
}
