import { defineStore } from 'pinia'
import { Cashier, Customers, Gateway, Stores } from 'package-types'
import { deleteToken, getToken, setToken } from '@/helpers/auth'
import router from '@/router'
import { publicRoutes } from '@/guard/auth'
import customerService from '@/services/customer-service'
import { notifyError, notifySuccess } from '@/helpers/notification'
import { isOver18 } from '@/helpers/validation'
import { setupLanguage } from '@/helpers/language'
import { i18n } from '@/i18n'
import { useSocket } from './socket'
import purchaseService from '@/services/purchase-service'
import { useBasketStore } from './basket'
import { useAppStore } from './app'
import cashierService from '@/services/cashier-service'
import { useStoreState } from './store'

type State = {
  customer: Customers.SafeCustomer | null
}

type Getters = {
  isAuthenticated(): boolean
  isOver18(): boolean
}

type Actions = {
  setCustomerData(userData: Gateway.Actions.CustomerLoginResult): void
  setCustomer(userData: Customers.SafeCustomer | null): void
  startUpCheck(): void
  updateCustomer(): void
  checkVisitorStatus(
    visitor: Stores.Visitors.SafeVisitor,
    updateQrCode: boolean
  ): void
  logout(notify?: boolean): void
}

export const useCustomerStore = defineStore<
  'customer',
  State,
  Getters,
  Actions
>('customer', {
  state() {
    return {
      customer: null
    }
  },
  getters: {
    isAuthenticated() {
      return !!this.customer
    },
    isOver18() {
      if (
        this.customer?.ageVerification?.provider ===
          Customers.AgeVerificationProvider.PxlVision &&
        this.customer?.birthDate
      ) {
        return isOver18(this.customer?.birthDate as unknown as string)
      }

      if (
        this.customer?.ageVerification?.provider ===
          Customers.AgeVerificationProvider.WalkoutAi &&
        this.customer?.ageVerification.recognizedAge
      ) {
        return this.customer?.ageVerification.recognizedAge >= 18
      }

      return false
    }
  },
  actions: {
    setCustomerData(userData) {
      this.setCustomer(userData.customer)
      setToken(userData.authToken)
    },

    setCustomer(customer) {
      this.customer = customer
      i18n.global.locale = this.customer?.lang as string
    },

    async updateCustomer() {
      const { customer } = await customerService.getCustomer()
      this.setCustomer(customer)
    },

    async logout(notify = false) {
      const { removeGlobalListeners } = useSocket()

      try {
        removeGlobalListeners()
        // eslint-disable-next-line no-empty
      } catch (e) {}

      this.customer = null
      deleteToken()
      if (notify) await notifyError('Session was ended')
    },

    async checkVisitorStatus(visitor, updateQrCode = true) {
      const { setBasket } = useBasketStore()
      const { startPurchase, endPurchase } = useAppStore()
      switch (visitor.status) {
        case Stores.Visitors.Status.WaitForEntranceDeposit:
          if (!updateQrCode) {
            const { deposit, depositSessions } =
              await cashierService.getPaymentState(visitor._id)

            for (const session of depositSessions) {
              if (session.state === Cashier.PaymentState.Started) {
                // if redirect -> 3D Secure -> consume
                // @ts-ignore
                // await cashierService.consumePayment(session.sessionId)
                notifySuccess(
                  `${deposit.amount} ${deposit.currency} was captured`
                )
              }
            }
          }
          return
        case Stores.Visitors.Status.Authenticated:
          startPurchase()
          return
        case Stores.Visitors.Status.DidNotEnter:
          endPurchase(updateQrCode)
          return
        case Stores.Visitors.Status.MadeDeposit:
          startPurchase()
          return
        case Stores.Visitors.Status.Shopping:
          startPurchase()
          // eslint-disable-next-line no-case-declarations
          const { basket } = await purchaseService.getBasket()
          setBasket(basket)
          return
        case Stores.Visitors.Status.EndOfTrip:
          endPurchase(updateQrCode)
          return
        case Stores.Visitors.Status.ForceLeftStore:
          endPurchase(updateQrCode)
          return
        default:
          console.log('unknown status')
          return
      }
    },

    async startUpCheck() {
      const { updateStore } = useStoreState()
      if (getToken()) {
        await this.updateCustomer()
        await updateStore()
        if (publicRoutes.includes(router.currentRoute.value.name as string)) {
          router.push({ name: 'Store' })
        }
        return
      }
      setupLanguage()
    }
  }
})
