import BaseService from '@/services/base-service'
import { Purchases } from 'package-types'

export default {
  async getBasket(): Promise<Purchases.Actions.AiBasketResult> {
    const { data } = await BaseService.get('/purchases/aiBasket')
    return data
  },
  async getPurchases(): Promise<Purchases.Actions.CustomerGetPurchasesResult> {
    const { data } = await BaseService.get('/purchases/customer/aiPurchases', {
      params: { limit: 999 }
    })
    return data
  }
}
