export const getToken = () => {
  return window.localStorage.getItem('kubler_token')
}

export const setToken = token => {
  window.localStorage.setItem('kubler_token', `${token}`)
}

export const deleteToken = () => {
  window.localStorage.removeItem('kubler_token')
}

export const getSavedState = (key: string) => {
  return JSON.parse(window.localStorage.getItem(key) as string)
}

export const saveState = (key: string, state: any) => {
  window.localStorage.setItem(key, JSON.stringify(state))
}
