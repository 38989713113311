import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { authGuard } from '@/guard/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Splash',
    meta: { authRequired: false },
    component: () => import('@/views/Splash.vue')
  },
  {
    path: '/instruction',
    name: 'Instruction',
    meta: { authRequired: false },
    component: () => import('@/views/Instruction.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    meta: { authRequired: false },
    component: () => import('@/views/SignIn.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: { authRequired: false },
    component: () => import('@/views/SignUp.vue')
  },
  {
    path: '/tos',
    name: 'Tos',
    meta: { authRequired: false },
    component: () => import('@/views/Tos.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { authRequired: false },
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { authRequired: false },
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: { authRequired: false },
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/store',
    name: 'Store',
    meta: { authRequired: true },
    component: () => import('@/views/Store.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: { authRequired: true },
    component: () => import('@/views/Account.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    meta: { authRequired: true },
    component: () => import('@/views/Payment/index.vue')
  },
  {
    path: '/payment/add',
    name: 'AddPayment',
    meta: { authRequired: true },
    component: () => import('@/views/Payment/AddPayment.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { authRequired: true },
    component: () => import('@/views/Orders/index.vue')
  },
  {
    path: '/age-verification',
    name: 'AgeVerification',
    meta: { authRequired: true },
    component: () => import('@/views/AgeVerification.vue')
  },
  {
    path: '/account/edit',
    name: 'EditCustomer',
    meta: { authRequired: true },
    component: () => import('@/views/EditCustomer.vue')
  },
  {
    path: '/account/change-password',
    name: 'ChangePassword',
    meta: { authRequired: true },
    component: () => import('@/views/ChangePassword.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authGuard)

export default router
