import { Customers } from 'package-types'

export default {
  common: {
    enter: '',
    confirm: 'confirm',
    save: 'save',
    below18: 'Your age under 18 (FSK18)',
    ageNotVerified: 'Age not verified',
    qrAgeChecked: 'Access to this area is allowed',
    back: 'back',
    validation: {
      required: 'This field is required',
      email: 'E-Mail invalid',
      phone: 'Invalid phone number',
      passwordMatch: 'Passwords must match',
      password:
        'The password must contain at least 6 characters, one uppercase and one lowercase letter'
    },
    ty: 'Thanks',
    successfulPayment: 'Your payment was successful',
    captured: 'was captured'
  },

  splash: {
    welcome: 'Welcome to',
    desc: 'Your digital supermarket for convenience and daily shopping!',
    go: 'Let’s go'
  },

  instruction: {
    slide1: 'Enter the store through the gate.',
    slide2: 'Remove your products from the shelf.',
    slide3:
      'For the purchase of FSK 18 products please use the verification in the app',
    slide4: '...& scan the QR code on the shelf.',
    slide5: 'Scan and pay for your items at the self-checkout exit',
    skip: 'Skip',
    snabble: {
      slide1: 'Please register and enter a credit card payment',
      slide2:
        'To purchase FSK 16 or FSK 18 products, please take verification in the app',
      slide3: 'Scan your QR code at the entrance gate',
      slide4: 'Then you can take the products you want'
    }
  },

  menu: {
    inStore: 'In Store',
    orders: 'Orders',
    payment: 'Payment',
    account: 'Account'
  },
  signIn: {
    title: 'Login',
    login_btn: 'Login',
    register_btn: 'Register',
    forgotPassword: 'Forgot password?',
    form: {
      emailLabel: 'Email',
      passwordLabel: 'Password'
    }
  },
  signUp: {
    title: 'Registration',
    personalInfo: 'Personal information',
    billingAddress: 'Billing address',
    password: 'Password',
    terms1: 'By creating an account, you agree to the',
    terms2: 'and',
    terms3: '',
    form: {
      firstName: 'First name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      repeatPassword: 'Password confirmation',
      address: 'Address',
      postalCode: 'Zip',
      city: 'Postcode',
      houseNumber: 'Number',
      phone: 'Phone Number',
      save: 'Register'
    }
  },
  store: {
    scanQr: 'Scan QR code to enter the store',
    wrongQr: 'Incorrect QR CODE. Please scan again',
    verificationStatuses: {
      [Customers.VerificationStatus.Completed]: 'Age verified',
      [Customers.VerificationStatus.Failed]: 'Age not verified',
      [Customers.VerificationStatus.InProgress]: 'Age verification in progress'
    },

    addPayment: 'You need to add payment method',
    selectPayment: 'You need to select default payment method',
    here: 'here'
  },
  account: {
    userData: 'User data',
    accountSettings: 'Account settings',
    language: 'Language',
    ageConfirmation: 'Age verification',
    privacy: 'Privacy Policy',
    dataProtection: 'Data Protection',
    changePassword: 'Change Password',
    logout: 'Logout',
    coupon: 'Coupon',
    lang_change: 'The language has been changed',
    languages: {
      de: 'Deutsch',
      en: 'English'
    },
    verificationStatuses: {
      [Customers.VerificationStatus.Completed]: 'Verified',
      [Customers.VerificationStatus.Failed]: 'Not verified',
      [Customers.VerificationStatus.InProgress]: 'In progress'
    }
  },
  editCustomer: {
    title: 'Edit data',
    save: 'save',
    save_success: 'Your data have been updated'
  },
  ageVerification: {
    title: 'Age verification',
    later: 'later',
    start: 'Start',
    takePhoto: 'Take photo',
    benefits: {
      id: {
        title: 'Forgot ID?',
        desc: 'Even without ID, on site, no problem.'
      },
      time: {
        title: 'Save time',
        desc: 'No further on-site verification required.'
      }
    },
    statuses: {
      [Customers.VerificationStatus.Completed]: 'Your age was verified',
      [Customers.VerificationStatus.Failed]: 'Verification failed',
      [Customers.VerificationStatus.InProgress]: 'Age verification pending...'
    },
    storeNotConfigured:
      "You can't verify your age right now. Please try again later"
  },
  changePassword: {
    newPassword: 'New password',
    currentPassword: 'Current password',
    repeatPassword: 'Repeat password',
    success: 'Password was changed'
  },
  forgotPassword: {
    title: 'Reset Password',
    reset: 'Reset',
    emailSent: 'A password reset link was sent',
    success: 'Password has been changed successfully'
  },
  voucher: {
    title: 'Coupon',
    subTitle: 'Your welcome offer!',
    desс: 'A gin tonic or hot drink for free',
    date: 'Valid until 07/20/2022'
  },
  basket: {
    title: 'Basket',
    problems: 'Problems?',
    tax: 'VAT',
    product: 'Products'
  },
  troubleshooting: {
    title: 'Troubleshooting'
  },
  payment: {
    title: 'Payment',
    add: 'Add payment method',
    default: 'Main Card',
    selectMain: 'Select'
  },
  addPayment: {
    title: 'Add Payment'
  },
  orders: {
    title: 'Orders',
    total: 'Total',
    orderFrom: 'Order From'
  }
}
