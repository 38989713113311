export const setupLanguage = () => {
  const savedLang = localStorage.getItem('kubler_lang')

  if (!savedLang) {
    switch (navigator.language) {
      case 'de':
        localStorage.setItem('kubler_lang', 'de')
        return 'de'
      default:
        localStorage.setItem('kubler_lang', 'en')
        return 'en'
    }
  }

  return savedLang
}
