import { Cashier, InstallType } from 'package-types'
import baseService from './base-service'

const STORE_ID = window.config.STORE_ID

export default {
  async getAvailablePaymentMethods(): Promise<Cashier.Actions.GetActivePaymentMethodsResult> {
    const { data } = await baseService.get('/cashier/paymentMethods', {
      params: {
        installType: InstallType.Store,
        installId: STORE_ID,
        provider: Cashier.PaymentApiProvider.VrPayment
      }
    })
    return data
  },

  async getPaymentTokens(): Promise<{
    tokens: Cashier.VrPayment.PaymentToken[]
  }> {
    const { data } = await baseService.get('/cashier/paymentTokens', {
      params: {
        installType: InstallType.Store,
        installId: STORE_ID,
        provider: Cashier.PaymentApiProvider.VrPayment
      }
    })
    return data
  },
  async getTestTokens(): Promise<{
    tokens: Cashier.Test.PaymentToken[]
  }> {
    const { data } = await baseService.get('/cashier/paymentTokens', {
      params: {
        installType: InstallType.Store,
        installId: STORE_ID,
        provider: Cashier.PaymentApiProvider.Test
      }
    })
    return data
  },

  async registerToken(
    channel: Cashier.VrPayment.PaymentChannel
  ): Promise<Cashier.VrPayment.StartPaymentTokenRegistrationResult> {
    const { data } = await baseService.post('/cashier/paymentToken/register', {
      installType: InstallType.Store,
      installId: STORE_ID,
      provider: Cashier.PaymentApiProvider.VrPayment,
      channel: channel
    })
    return data
  },

  async registerTestToken(): Promise<Cashier.Test.StartPaymentTokenRegistrationResult> {
    const { data } = await baseService.post('/cashier/paymentToken/register', {
      installType: InstallType.Store,
      installId: STORE_ID,
      provider: Cashier.PaymentApiProvider.Test,
      testCase: 'SuccessfulPaymentFlow'
    })
    return data
  },

  async checkToken(
    checkoutId: string
  ): Promise<Cashier.VrPayment.CheckPaymentTokenStatusResult> {
    const { data } = await baseService.post('/cashier/paymentToken/check', {
      installType: InstallType.Store,
      installId: STORE_ID,
      provider: Cashier.PaymentApiProvider.VrPayment,
      channel: Cashier.VrPayment.PaymentChannel.CreditCard,
      checkoutId
    })
    return data
  },

  async checkTestToken(
    tokenId
  ): Promise<Cashier.Test.CheckPaymentTokenStatusResult> {
    const { data } = await baseService.post('/cashier/paymentToken/check', {
      installType: InstallType.Store,
      installId: STORE_ID,
      provider: Cashier.PaymentApiProvider.Test,
      tokenId
    })
    return data
  },

  async removeToken(tokenId: string): Promise<{ success: boolean }> {
    const { data } = await baseService.delete(
      `/cashier/paymentTokens/${tokenId}`,
      {
        params: {
          installType: InstallType.Store,
          installId: STORE_ID,
          provider: Cashier.PaymentApiProvider.VrPayment
        }
      }
    )
    return data
  },

  async removeTestToken(tokenId: string): Promise<{ success: boolean }> {
    const { data } = await baseService.delete(
      `/cashier/paymentTokens/${tokenId}`,
      {
        params: {
          installType: InstallType.Store,
          installId: STORE_ID,
          provider: Cashier.PaymentApiProvider.Test
        }
      }
    )
    return data
  },

  async setDefaultTestToken(tokenId) {
    const { data } = await baseService.post(
      `/cashier/paymentTokens/default/${tokenId}`,
      {
        installType: InstallType.Store,
        installId: STORE_ID,
        provider: Cashier.PaymentApiProvider.Test
      }
    )
    return data
  },

  async setDefaultToken(tokenId) {
    const { data } = await baseService.post(
      `/cashier/paymentTokens/default/${tokenId}`,
      {
        installType: InstallType.Store,
        installId: STORE_ID,
        provider: Cashier.PaymentApiProvider.VrPayment
      }
    )
    return data
  },

  async consumePayment(sessionId) {
    const { data } = await baseService.post(
      '/cashier/customer/payment/consume',
      { sessionId }
    )
    return data
  },

  async getPaymentState(
    visitorId
  ): Promise<Cashier.Actions.GetVisitorPaymentStateResult> {
    const { data } = await baseService.get(
      `/cashier/customer/visitor/${visitorId}/state`
    )
    return data
  }
}
