import { modalController } from '@ionic/vue'
import { ComponentRef } from '@ionic/core'
import LanguageModal from '@/components/modal/LanguageModal.vue'
import PrivacyModal from '@/components/modal/PrivacyModal.vue'
import DataProtectModal from '@/components/modal/DataProtectModal.vue'
import VoucherModal from '@/components/modal/VoucherModal.vue'
import TroubleshootingModal from '@/components/modal/TroubleshootingModal.vue'
import QrCodeModal from '@/components/modal/QrCodeModal.vue'
import PurchaseModal from '@/components/modal/PurchaseModal.vue'
import { Purchases } from 'package-types'

export const openLanguageModal = async () => {
  const modal = await modalController.create({
    component: LanguageModal as ComponentRef,
    cssClass: 'language-modal'
  })
  return modal.present()
}

export const openPrivacyModal = async () => {
  const modal = await modalController.create({
    component: PrivacyModal as ComponentRef,
    cssClass: 'privacy-modal'
  })
  return modal.present()
}

export const openDataProtectModal = async () => {
  const modal = await modalController.create({
    component: DataProtectModal as ComponentRef,
    cssClass: 'data-modal'
  })
  return modal.present()
}

export const openVoucherModal = async () => {
  const modal = await modalController.create({
    component: VoucherModal as ComponentRef,
    cssClass: 'data-modal'
  })
  return modal.present()
}

export const openTroubleshootingModal = async () => {
  const modal = await modalController.create({
    component: TroubleshootingModal as ComponentRef,
    cssClass: 'data-modal'
  })
  return modal.present()
}

export const openQrCodeModal = async () => {
  const modal = await modalController.create({
    component: QrCodeModal as ComponentRef,
    cssClass: 'qr-modal'
  })
  return modal.present()
}

export const openPurchaseModal = async (purchase: Purchases.AiPurchase) => {
  const modal = await modalController.create({
    component: PurchaseModal as ComponentRef,
    componentProps: { purchase },
    cssClass: 'privacy-modal'
  })
  return modal.present()
}

export const dismissCurrentModal = async () => {
  if (await modalController.getTop()) {
    await modalController.dismiss()
  }
}
