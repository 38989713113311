import customerService from '@/services/customer-service'
import { defineStore } from 'pinia'
import { useBasketStore } from './basket'

type State = {
  loading: boolean
  isShowingPaymentSuccess: boolean
  isLoadingQrToken: boolean
  isPurchaseStarted: boolean
  qrToken: string | null
}

type Getters = {
  isLoading(): boolean
}

type Actions = {
  startLoading(): void
  stopLoading(): void
  startPurchase(): void
  showPaymentSuccess(): void
  updateQrToken(): void
  endPurchase(updateQrCode: boolean): void
  setQrToken(qrToken: string): void
}

export const useAppStore = defineStore<'app', State, Getters, Actions>('app', {
  state() {
    return {
      loading: true,
      qrToken: null,
      isLoadingQrToken: false,
      isShowingPaymentSuccess: false,
      isPurchaseStarted: false
    }
  },
  getters: {
    isLoading() {
      return this.loading
    }
  },
  actions: {
    startLoading() {
      this.loading = true
    },
    setQrToken(qrToken) {
      this.qrToken = qrToken
      // eslint-disable-next-line no-console
      console.log(this.qrToken)
    },
    stopLoading() {
      setTimeout(() => (this.loading = false), 1000)
    },
    startPurchase() {
      this.isPurchaseStarted = true
    },

    async updateQrToken() {
      this.isLoadingQrToken = true
      const { qrCode } = await customerService.getQrCode()
      this.setQrToken(qrCode)
      this.isLoadingQrToken = false
    },
    showPaymentSuccess() {
      this.isShowingPaymentSuccess = true

      setTimeout(() => {
        this.isShowingPaymentSuccess = false
      }, 3000)
    },
    async endPurchase(updateQrCode = true) {
      const { clearBasket } = useBasketStore()
      if (updateQrCode) {
        const { qrCode } = await customerService.getQrCode()
        this.setQrToken(qrCode)
      }
      this.isPurchaseStarted = false
      clearBasket()
    }
  }
})
