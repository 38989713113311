import { toastController } from '@ionic/vue'

export const notifySuccess = async (message: string) => {
  ;(
    await toastController.create({
      message: message,
      cssClass: 'success',
      duration: 2000
    })
  ).present()
}

export const notifyError = async (message: string) => {
  ;(
    await toastController.create({
      message: message,
      cssClass: 'error',
      duration: 2000
    })
  ).present()
}

export const notifyWarning = async (message: string) => {
  ;(
    await toastController.create({
      message: message,
      cssClass: 'warning',
      duration: 2000
    })
  ).present()
}
