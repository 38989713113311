import { ImageResponse } from '@/models/Images'
import BaseService from '@/services/base-service'

export default {
  async getImages(
    imageIds: string[]
  ): Promise<{ imagesLinks: ImageResponse } | { imagesLinks: null }> {
    if (imageIds.length === 0) return { imagesLinks: null }
    const { data } = await BaseService.get('/file/images', {
      params: {
        imageIds
      }
    })
    return data
  }
}
