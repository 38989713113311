import { defineStore } from 'pinia'
import { io, Socket } from 'socket.io-client'
import { getToken } from '@/helpers/auth'
import { useAppStore } from './app'
import { SocketRoutes } from '@/models/enums/SocketRoutes'
import { Purchases } from 'package-types'
import { useBasketStore } from './basket'
import customerService from '@/services/customer-service'
import { useCustomerStore } from './customer'
import { Stores } from 'package-types'
import { notifySuccess } from '@/helpers/notification'
import { Cashier } from 'package-types'
import cashierService from '@/services/cashier-service'
import { dismissCurrentModal } from '@/helpers/modal'

export const useSocket = defineStore('socket', {
  state: () => ({
    socket: {} as Socket
  }),

  actions: {
    setupSocketConnection() {
      const { startLoading, stopLoading, showPaymentSuccess } = useAppStore()

      const { setBasket } = useBasketStore()
      const { checkVisitorStatus } = useCustomerStore()
      startLoading()
      this.socket = io(window.config.API_URL as string)
      this.socket.on(SocketRoutes.CONNECT, async () => {
        this.socket.emit('join', 'customer', getToken())
        const { visitor } = await customerService.getVisitor()
        if (visitor) {
          checkVisitorStatus(visitor, false)
        }
        stopLoading()
      })

      this.socket.on(
        SocketRoutes.PURCHASES_AI_BASKET_PRODUCTS_CHANGED,
        ({ basket }: { basket: Purchases.AiBasket }) => {
          setBasket(basket)
        }
      )
      this.socket.on(
        SocketRoutes.CASHIER_PAYMENT_SESSION_STARTED,
        async (session: Cashier.Events.CashierPaymentSessionStarted) => {
          // if status successful or failed no need. if pending and redirect -> iframe 3D secure -> consume
          // if (session.result.status === Cashier.OperationStatus.Pending) {
          //   // await cashierService.consumePayment(session.sessionId)
          //   // notifySuccess(
          //   //   `${session.money.amount} ${session.money.currency} was captured`
          //   // )
          // }
          //
          notifySuccess(
            `${session.money.amount} ${session.money.currency} was captured`
          )
        }
      )

      this.socket.on(
        SocketRoutes.STORES_VISITOR_STATUS_CHANGED,
        ({ visitor }: { visitor: Stores.Visitors.SafeVisitor }) => {
          dismissCurrentModal()
          checkVisitorStatus(visitor, true)
        }
      )

      this.socket.on(
        SocketRoutes.PURCHASES_AI_PURCHASE_PAYMENT_SUCCESSFUL,
        () => {
          showPaymentSuccess()
        }
      )
    },
    removeGlobalListeners() {
      if (!this.socket) return
      this.socket.removeAllListeners(
        SocketRoutes.PURCHASES_AI_BASKET_PRODUCTS_CHANGED
      )
      this.socket.removeAllListeners(SocketRoutes.STORES_VISITOR_STATUS_CHANGED)
      this.socket.removeAllListeners(
        SocketRoutes.PURCHASES_AI_PURCHASE_PAYMENT_SUCCESSFUL
      )
    }
  }
})
