import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { StatusBar } from '@capacitor/status-bar'
import Maska from 'maska'
import { createPinia } from 'pinia'
import { IonicVue, isPlatform } from '@ionic/vue'
import { i18n } from '@/i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import { useCustomerStore } from '@/state/customer'
import { useSocket } from '@/state/socket'
import { useAppStore } from './state/app'

if (config.BRAND === 'kuebler') {
  require('./theme/variablesKuebler.css')
}

if (config.BRAND === 'snabble') {
  require('./theme/variablesSnabble.css')
}

if (config.TITLE) {
  document.title = config.TITLE
}

if (isPlatform('ios') || isPlatform('android')) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  StatusBar.hide().catch(() => {})
}

createApp(App)
  .use(IonicVue)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .use(Maska)
  .mount('#app')

router.isReady().then(async () => {
  const auth = useCustomerStore()
  const app = useAppStore()
  const socket = useSocket()
  app.startLoading()
  await auth.startUpCheck()
  if (auth.isAuthenticated) {
    socket.setupSocketConnection()
    return
  }

  app.stopLoading()
})
