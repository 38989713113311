import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useCustomerStore } from '@/state/customer'
import { getToken } from '@/helpers/auth'

export function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> | void {
  const auth = useCustomerStore()
  if (!to.meta.authRequired || getToken()) {
    next()
  } else {
    next({ name: 'Splash' })
    auth.logout(true)
  }
}

export const publicRoutes = ['SignIn', 'SignUp', 'Splash', 'Instruction']
