export const formatPrice = (price: string | any) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(parseFloat(price))
}

export const getDate = val => {
  const date = new Date(val)
  return `${date.toDateString()}, ${date.getHours()}:${date.getMinutes()}`
}

export const splitByUpperCase = str => {
  return str.split(/(?=[A-Z])/).join(' ')
}
