import { i18n } from '@/i18n'
import * as yup from 'yup'

const { t } = i18n.global

export const signUpValidationSchema = yup.object({
  firstName: yup.string().required(() => t('common.validation.required')),
  lastName: yup.string().required(() => t('common.validation.required')),
  phone: yup
    .string()
    .required(() => t('common.validation.required'))
    .min(10, () => t('common.validation.phone')),
  email: yup
    .string()
    .required(() => t('common.validation.required'))
    .email(() => t('common.validation.email')),
  billingAddress: yup.object({
    address: yup.string().required(() => t('common.validation.required')),
    houseNumber: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(() => t('common.validation.required')),
    postalCode: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(() => t('common.validation.required')),

    city: yup.string().required(() => t('common.validation.required'))
  }),
  password: yup
    .string()
    .required(() => t('common.validation.required'))
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, () =>
      t('common.validation.password')
    ),
  repeatPassword: yup
    .string()
    .required(() => t('common.validation.required'))
    .oneOf([yup.ref('password'), null], () =>
      t('common.validation.passwordMatch')
    )
})

export const editCustomerValidationSchema = yup.object({
  firstName: yup.string().required(() => t('common.validation.required')),
  lastName: yup.string().required(() => t('common.validation.required')),
  phone: yup
    .string()
    .required(() => t('common.validation.required'))
    .min(10, () => t('common.validation.phone'))
    .max(13, () => t('common.validation.phone')),
  billingAddress: yup.object({
    address: yup.string().required(() => t('common.validation.required')),
    houseNumber: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(() => t('common.validation.required')),
    postalCode: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required(() => t('common.validation.required')),

    city: yup.string().required(() => t('common.validation.required'))
  })
})

export const SignInValidationSchema = yup.object({
  email: yup
    .string()
    .required(() => t('common.validation.required'))
    .email(() => t('common.validation.email')),
  password: yup.string().required(() => t('common.validation.required'))
})

export const ForgotPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .required(() => t('common.validation.required'))
    .email(() => t('common.validation.email'))
})

export const ResetPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .required(() => t('common.validation.required'))
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, () =>
      t('common.validation.password')
    ),
  repeatPassword: yup
    .string()
    .required(() => t('common.validation.required'))
    .oneOf([yup.ref('password'), null], () =>
      t('common.validation.passwordMatch')
    )
})

export const ChangePasswordValidationSchema = yup.object({
  currentPassword: yup
    .string()
    .required(() => t('common.validation.required'))
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, () =>
      t('common.validation.password')
    ),
  newPassword: yup
    .string()
    .required(() => t('common.validation.required'))
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, () =>
      t('common.validation.password')
    ),
  repeatPassword: yup
    .string()
    .required(() => t('common.validation.required'))
    .oneOf([yup.ref('newPassword'), null], () =>
      t('common.validation.passwordMatch')
    )
})

export const isOver18 = (dateOfBirth: string) => {
  const date18YrsAgo = new Date()
  date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18)
  return new Date(dateOfBirth) <= date18YrsAgo
}
