export enum SocketRoutes {
  STORES_VISITOR_WRONG_QR_CODE = 'STORES_VISITOR_WRONG_QR_CODE',
  CONNECT = 'connect',
  CUSTOMERS_AGE_VERIFICATION_FINISHED = 'CUSTOMERS_AGE_VERIFICATION_FINISHED',
  CUSTOMERS_AGE_CHECKED_BY_QR_CODE = 'CUSTOMERS_AGE_CHECKED_BY_QR_CODE',
  STORES_VISITOR_CAME_IN = 'STORES_VISITOR_CAME_IN',
  PURCHASES_AI_PURCHASE_PAYMENT_SUCCESSFUL = 'PURCHASES_AI_PURCHASE_PAYMENT_SUCCESSFUL',
  STORES_VISITOR_LEAVE_STORE = 'STORES_VISITOR_LEAVE_STORE',
  PURCHASES_AI_BASKET_PRODUCTS_CHANGED = 'PURCHASES_AI_BASKET_PRODUCTS_CHANGED',
  STORES_VISITOR_STATUS_CHANGED = 'STORES_VISITOR_STATUS_CHANGED',
  CASHIER_PAYMENT_SESSION_STARTED = 'CASHIER_PAYMENT_SESSION_STARTED'
}
