import { ImageResponse } from '@/models/Images'
import fileService from '@/services/file-service'
import { Purchases } from 'package-types'
import { defineStore } from 'pinia'

type State = {
  basket: Purchases.AiBasket | null
  basketImages: ImageResponse | null
}

type Getters = any

type Actions = {
  setBasket(basket: Purchases.AiBasket | null): void
  clearBasket(): void
  generateImagesFromBasket(products: Purchases.AiBasketProduct[]): string[]
}

export const useBasketStore = defineStore<'basket', State, Getters, Actions>(
  'basket',
  {
    state: () => ({
      basket: null,
      basketImages: null
    }),
    actions: {
      async setBasket(basket) {
        this.basket = basket
        if (basket) {
          const { imagesLinks } = await fileService.getImages(
            this.generateImagesFromBasket(basket.products)
          )
          this.basketImages = imagesLinks
        }
      },

      generateImagesFromBasket(products: Purchases.AiBasketProduct[]) {
        let allImageKeys = []
        products.forEach(product => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (product.images) allImageKeys = allImageKeys.concat(product.images)
        })
        return [...new Set(allImageKeys)]
      },
      clearBasket() {
        this.basket = null
        this.basketImages = null
      }
    }
  }
)
