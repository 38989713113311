import storeService from '@/services/store-service'
import { Cashier } from 'package-types'
import { Stores } from 'package-types'
import { defineStore, Store } from 'pinia'

type State = {
  store: Stores.SafeStore | null
}

type Getters = {
  paymentProvider(): Cashier.PaymentApiProvider | undefined
}

type Actions = {
  updateStore()
}

export const useStoreState = defineStore<'store', State, Getters, Actions>(
  'store',
  {
    state: () => ({
      store: null
    }),

    getters: {
      paymentProvider() {
        return this.store?.defaultPaymentApiProvider
      }
    },
    actions: {
      async updateStore() {
        const { store } = await storeService.getStore(window.config.STORE_ID)
        this.store = store
      }
    }
  }
)
