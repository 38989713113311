import axios from 'axios'
import BaseInterceptors from '@/services/interceptors/base-interceptors'

const baseDomain = config.API_URL
const baseURL = `${baseDomain}`

const axiosInstance = axios.create({
  baseURL
})

BaseInterceptors.use(axiosInstance)

export default axiosInstance
